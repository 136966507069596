
/* @font-face {
    font-family: 'Tahoma-Bold';
    src: url('assets/font/TAHOMABD.ttf') format('truetype');
}
@font-face {
    font-family: 'Tahoma-Regular';
    src: url('assets/font/Tahoma Regular font.ttf') format('truetype');
}
@font-face {
    font-family: 'Tahoma-Regular';
    src: url('assets/font/Tahoma Regular font.ttf') format('truetype');
}
@font-face {
    font-family: 'sign-amandasignature';
    src: url('assets/font/amandasignature.ttf') format('truetype');
}
@font-face {
    font-family: 'sign-geovana';
    src: url('assets/font/Geovana.ttf') format('truetype');
}
@font-face {
    font-family: 'sign-julietta';
    src: url('assets/font/Julietta.otf') format('opentype');
}
@font-face {
    font-family: 'sign-vtks-hunt';
    src: url('assets/font/VtksHunt.ttf') format('truetype');
}
@font-face {
    font-family: 'falma';
    src: url('assets/font/Flama-Basic.woff2') format('woff2');
}
@font-face {
    font-family: 'falma';
    font-weight: bold;
    src: url('assets/font/Flama-Semibold.woff2') format('woff2');
}
 */

@font-face {
    font-family: 'Mulish';
    font-weight: bold;
    src: url('assets/font/Mulish-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    src: url('assets/font/Mulish-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 600;
    src: url('assets/font/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 500;
    src: url('assets/font/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 400;
    src: url('assets/font/Mulish-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 300;
    src: url('assets/font/Mulish-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 200;
    src: url('assets/font/Mulish-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish';
    font-weight: 100;
    src: url('assets/font/Mulish-ExtraLight.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: Mulish, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1rem;
  color: #000000;
}
html {
    height: 100%;
    scroll-behavior: smooth;
}
body * {
    font-family: Mulish, sans-serif !important;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: row;
}

#root .left-sidebar {
    flex-grow: 1;
    max-width: 220px;
    min-width: 220px;
}


#root .main-container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 100;
    overflow-x: hidden;
    overflow-y: auto;
    background: #F3F2FF;
}
#root .main-container .global-header {
    position: sticky;
    top: 0;
    z-index: 50;
}

#root .main-container .page {
    flex-grow: 100;
}

#root .main-container .ContactSidebar{
    position: absolute;
    top: 76px;
    right: 0;
    width: 240px;
    height: calc(100vh - 76px);
    background-color: white;
    border-left: 1px solid #e1e1e1;
}

#root .main-container .global-header .search {
    width: 215px;
}

#root .main-container > .page{
    width: calc(100% - 240px);
}

* {
    box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#form-dialog-title h2{
    color: #37BFFF;
}

.sign_pad_wrapper{
    margin-bottom: 16px;
    /* height: 10rem; */
}
.sign_pad_wrapper .preload_font{
    /* display: none; */
    width: 1px;
    height: 1px;
    display: inline-block;
    opacity: 0;
}
.sign_pad_wrapper .preload_font.type1{
    font-family: 'sign-amandasignature';
}
.sign_pad_wrapper .preload_font.type2{
    font-family: 'sign-geovana';
}
.sign_pad_wrapper .preload_font.type3{
    font-family: 'sign-julietta';
}
.sign_pad_wrapper .preload_font.type4{
    font-family: 'sign-vtks-hunt';
}


.sign_pad_wrapper canvas{
    border: 1px solid #EEEEEE;
    background: white;
}

.react-code-input input{
    font-size: 1rem !important;
    width: 28px !important;
    height: 40px !important;
    line-height: 3.5rem !important;
    border: 0px solid #9e9e9e !important;
    background: #fff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.react-code-input input:focus{
    outline: none !important;
    border: 1px solid #229429 !important;
}
.react-code-input input[type=number]::-webkit-inner-spin-button,
.react-code-input input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

.social_number .react-code-input input:nth-child(3),
.social_number .react-code-input input:nth-child(5){
    margin-right: 1.5rem !important;
}

.employee_number .react-code-input input:nth-child(2){
    margin-right: 1.5rem !important;
}

.form-select{
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: 1px solid #E1E1E1;
    height: 40px;
    font-size: 1rem;
    padding-left: 10px;
    border-radius: 3px;
}
.form-select::after, .form-select::before {
    border-bottom: 0 solid white !important;
}
.error-message{
    background-color: #ffb1b17a;
    color: #e80000;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
}
.success-message{
    background-color: #cee4ff;
    color: #003a80;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.datepicker.common{
    width: 100%;
    height: 40px;
    padding-top: 5px;
    font-size: 1rem;
    padding-left: 10px;
    border-radius: 8px;
    background: rgba(194, 194, 194, 0.06);
    border: 1px solid rgba(18, 145, 249, 0.18);
}
.datepicker.common input {
    cursor: pointer;
}
.datepicker.common > div::before,
.datepicker.common > div::after{
    border-bottom: 0 !important;
}

.qb-button{
    outline: none;
    -webkit-appearance: none;
    border: 0;
    width: 100%;
    height: 34px;
    background: url('/assets/img/quickbook/C2QB_green_btn_lg_default.png') no-repeat;
    background-position: center center;
    background-size: contain;
}
.qb-button:hover{
    background-image: url('/assets/img/quickbook/C2QB_green_btn_lg_hover.png');
}
.qb-button.pending{
    background: url('/assets/img/quickbook/C2QB_green_btn_lg_pending.png') no-repeat;
    background-position: center center;
    background-size: contain;
}
.qb-button.pending:hover{
    background: url('/assets/img/quickbook/C2QB_green_btn_lg_hover_pending.png') no-repeat;
    background-position: center center;
    background-size: contain;
}



.paypal-button{
    outline: none;
    -webkit-appearance: none;
    border: 0;
    width: 100%;
    height: 34px;
    background-color: '#FFC43A';
    /* border-radius: 17px;
    background: url('/assets/img/paypal/paypal.png') no-repeat #FFC43A; */
    background-repeat: no-repeat;
    background-size: 75px 21px;
    background-position: center center;
}
.paypal-button:hover{
    background-color: '#ffb300';
}
.paypal-button.pending{
    background: url('/assets/img/paypal/paypal_pending.png') no-repeat;
    background-position: center center;
    background-size: 75px 21px;
}
.paypal-button.pending:hover{
    background: url('/assets/img/paypal/paypal_pending.png') no-repeat;
    background-position: center center;
    background-size: 75px 21px;
}

.suggession-type1 .react-autosuggest__container{
    position: relative;
    display: inline-block;
    padding-left: 1rem;
}
.suggession-type1 .react-autosuggest__container input{
    max-width: 3rem;
}
.suggession-type1 .react-autosuggest__container .react-autosuggest__suggestions-container{
    display: none;
}
.suggession-type1 .react-autosuggest__container.react-autosuggest__container--open .react-autosuggest__suggestions-container{
    display: block;
    position: absolute;
    top: 35px;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: white;
    border: 1px solid #999;
    border-radius: 5px;
    box-shadow: aliceblue;
}
.suggession-type1 .react-autosuggest__container.react-autosuggest__container--open .react-autosuggest__suggestions-container::before{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -5px;
    left: 1rem;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #999;
}
.suggession-type1 .react-autosuggest__container ul{
    list-style: none;
    padding: 0;
}

.input-errror {
  background-color: #fff2f2;
  color: #a30c0c;
  margin-top: 0.3rem !important;
  padding: 0.3rem;
  border-radius: 0.3rem;
  text-indent: 0.5rem;
  font-size: 80%;
}

.Autocomplete-Wrapper.register-form-input > div {
    display: block !important;
}
.Autocomplete-Wrapper.register-form-input > div > div {
    padding: 1rem !important;
    background-color: white !important;
    border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
    font-size: 100% !important;
    z-index: 20;
}
.organization-autocomplete {
    width: 100%;
    border: 1px solid #e1e1e1;
    height: 40px;
    position: relative;
    flex-grow: 1;
    font-size: 1rem;
    background: #ffffff;
    margin-top: 40px;
    padding-left: 20px;
    border-radius: 3px;
}

.react-datepicker-wrapper {
    display: block !important;
    width: 100%;
}
.react-datepicker__input-container input {
    background: rgba(194, 194, 194, 0.06);
    height: 40px;
    font-size: 1rem;
    padding-left: 10px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid rgba(18, 145, 249, 0.18);
}

.react-datepicker__input-container input:focus {
    outline: none;
}

#root > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.alert-title {
    color: #0072BB
}
.alert-container {
    min-width: 450px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: initial;
}

.scrollbar .scroll-y
{
	overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #1d2b36;
	border: 1px solid #1d2b36;
}

.budget-scroll-bar::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

.budget-scroll-bar::-webkit-scrollbar {
    width: 8px;
    background: rgba(255, 255, 255, 0);
}

.budget-scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #F2F2F2;
    height: 83px;
}

button:focus {
    outline: none;
}

.react-autosuggest__container {
    position: relative;
}
.react-autosuggest__container::after {
    display: block;
    right: 8px;
    top: calc(50% - 3px);
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 3px 0 3px;
    border-color: #555 transparent transparent transparent;
}
.react-autosuggest__container.react-autosuggest__container--open::after {
    border-width: 0 3px 6px 3px;
    border-color: transparent transparent #555 transparent;
}

.simple-select {
    display: inline-block;
    min-width: 6rem;
}

.simple-select:focus {
    outline: none;
}
.input-elem.disabled,
.MuiInputBase-root.Mui-disabled,
.react-datepicker__input-container input:disabled {
    background-color: #F2F2F2;
}

.dropdown-toggle {
    font-size: 14px !important;
}